body {
  color: #828282;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

a {
  color: #155FCE;
  transition: 0.3s;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
  color: #333;
}

.base-color {
  color: #155FCE !important;
}

.base-bg {
  background-color: #155FCE !important;
}

.bg-light {
  background-color: #F7F7FA !important;
}

.bg-success {
  background: #51CB85 !important;
}

.btn-outline-white {
  border: 2px solid rgba(255, 255, 255, 0.35) !important;
  border-radius: 8px;
  color: #fff !important;
  background-color: transparent !important;
}

.btn-outline-white:hover {
  background-color: #fff !important;
  color: #155FCE !important;
}

.btn {
  border-radius: 8px;
  font-weight: bold;
  padding: .6rem 1rem;
  align-items: center;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  color: #155FCE;
}

.btn svg {
  margin-right: 10px;
}

.btn.focus, .btn:focus, button:focus {
  outline: 0;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgba(81, 203, 133, .9);
  border-color: rgba(81, 203, 133, .9);
}

.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: rgba(81, 203, 133, .9);
  border-color: rgba(81, 203, 133, .9);
  box-shadow: none;
}

.btn:hover {
  background-color: #155FCE;
  border-color: #155FCE;
  color: #fff;
}

.btn-primary {
  background-color: #155FCE;
  border-color: #155FCE;
  color: #fff;
}

.btn-primary:hover {
  background-color: rgba(21, 95, 206, .9);
  border-color: rgba(21, 95, 206, .9);
}

.btn-success {
  background-color: #51CB85;
  border-color: #51CB85;
  color: #fff;
}

.btn-success:hover {
  background-color: rgba(81, 203, 133, .9);
  border-color: rgba(81, 203, 133, .9);
}

.section {
  padding: 60px 0;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #51CB85;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 8px;
  right: 10px;
  bottom: 50px;
  transition: background 0.5s;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.text-shadow {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.form-control {
  color: #333;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  height: calc(2em + 1rem + 2px);
}

label {
  font-weight: bold;
  color: #222E3D;
}

.form-control::-webkit-input-placeholder {
  color: #BDBDBD;
}

.form-control::-moz-placeholder {
  color: #BDBDBD;
}

.form-control:-ms-input-placeholder {
  color: #BDBDBD;
}

.form-control:-moz-placeholder {
  color: #BDBDBD;
}

.form-control:focus, .custom-select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #E0E0E0;
}

.custom-select {
  color: #155FCE;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  height: calc(2em + .75rem + 2px);
  padding: .375rem 3rem .375rem .75rem;
  background: url(../assets/select-down-arrow.svg) no-repeat right 1rem center;
}

/* Header section css start */

header {
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  padding-top: 1rem;
}

header.sticky {
  padding-top: 0rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

header.sticky .header-navbar {
  padding: 1rem 0;
}

header.sticky .navbar-brand .logo {
  max-height: 50px;
}

.header-navbar {
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  padding: 2rem 0;
}

.navbar-brand .logo {
  max-width: 100%;
}

.navbar-dark .navbar-toggler {
  color: #51CB85;
  border-color: #51CB85;
  background-color: #51CB85;
}

.header-navbar .navbar-nav .nav-item {
  margin: 0 1rem;
}

.header-navbar .navbar-nav .nav-link {
  padding: .7rem 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.header-navbar .navbar-nav .nav-item:hover .nav-link {
  background-color: #0D53BC;
  border-radius: 8px;
}

.header-navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 1);
}

.header-navbar .navbar-nav .btn {
  margin-left: 1rem;
}

/* Header section css end */

/* Hero Banner section css start */

.section.hero {
  padding: 0;
  overflow: hidden;
}

.hero .hero-banner {
  position: relative;
  padding: 15% 0 18% 0;
}

.hero .hero-banner h1 {
  color: #fff;
  font-size: 50px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.hero .hero-banner h2 {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 400;
  font-size: 40px;
  margin-bottom: 3rem;
}
.hero .hero-banner h1
.hero .hero-banner h2,
.hero .hero-banner .btn {
  position: relative;
  z-index: 1;
}

.hero .hero-banner .hero-img {
  position: absolute;
  right: -29%;
  bottom: 0;
  max-width: 95%;
}

/* Hero Banner section css end */

/* Customer Section start css */

.customer-logos .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.customer-logos .slick-loading .slick-track {
  visibility: hidden;
}

.customer-logos .slick-slide {
  float: left;
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.customer-container h2 {
  font-size: 2.75rem;
}

.customer-container {
  padding-top: 3rem;
  font-size: 20px;
}

.customer-container p:last-child {
  margin-bottom: 0rem;
}

/* Customer Section css end */

/* Works Section css start */

.works-section {
  font-size: 20px;
}

.works-section .title {
  font-size: 3.5rem;
}

.works-section .step-title {
  font-size: 2.75rem;
  margin-bottom: 1rem;
}

.works-section p:last-child {
  margin-bottom: 0rem;
}

.works-section .works-step {
  padding: 3rem 0;
}

.works-step .step-description-left {
  padding-right: 3rem;
}

.works-step .step-description-right {
  padding-left: 3rem;
}

.works-step .button-group {
  padding-top: 0.5rem;
}

/* Works Section css end */

/* Trial Section css start */

.trial-section {
  position: relative;
  padding: 130px 0 120px 0;
  background: url(../assets/trial-section-bg.png) #51CB85;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right center;
}

.trial-section-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.trial-section h2 {
  font-size: 4rem;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin: 0;
}

.trial-section .call {
  display: flex;
  align-items: center;
  box-shadow: 0px 48px 60px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background-color: #fff;
  padding: 30px;
  margin: 0 6%;
}

.trial-section .call .call-icon {
  width: 40px;
}

.trial-section .call .call-info {
  width: calc(100% - 80px);
  margin-left: 30px;
  font-size: 24px;
  font-family: 'Source Code Pro', monospace;
}

.trial-section .call .call-info a, .trial-section .call .call-info span {
  font-weight: bold;
}

/* Trial Section css end */

/* Dashboard Screen Section css start */

.dashboard-screen {
  font-size: 20px;
  padding: 100px 0 0 0;
}

.dashboard-screen .container-fluid {
  padding: 0;
}

.dashboard-screen .description {
  max-width: 320px;
  float: right;
}

.dashboard-screen h2 {
  font-size: 2.75rem;
  margin: 2rem 0 1rem 0;
}

.dashboard-screen p:last-child {
  margin-bottom: 0rem;
}

.dashboard-screen .play-icon {
  box-shadow: 0px 48px 60px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
}

.dashboard-screen .dashboard-screen-img {
  width: 100%;
}

/* Dashboard Screen Section css end */

/* FAQs Section css start */

.faqs {
  color: hsla(0,0%,100%,.75);
  font-size: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.faqs .title {
  color: #fff;
  font-size: 60px;
}

.faqs .btn.btn-outline-white {
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}

.faqs .btn.btn-outline-white:hover {
  color: #fff !important;
  background-color: transparent !important;
}

.faqs .faq-close {
  display: none;
}

.faqs .collapsed .faq-close {
  display: inline-block;
}

.faqs .collapsed .faq-open {
  display: none;
}

.faqs .collapsed .arrow {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}

.faqs .arrow {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.faqs .faqs-questions {
  padding-top: 100px;
}

.faqs .faqs-questions h3 {
  color: #fff;
  margin-bottom: 1rem;
}

.faqs .faqs-questions hr {
  border-color: rgba(255, 255, 255, 0.15);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* FAQs Section css end */

/* Pricing Section css start */

.pricing {
  font-size: 20px;
  padding: 100px 0;
}

.pricing .title {
  font-size: 60px;
}

.pricing .card {
  border-radius: 12px;
  border: none;
  overflow: hidden;
  padding: 30px;
  transition: 0.4s;
}

.pricing .card .card-header {
  border: none;
  background-color: transparent;
}

.pricing .card .card-body .card-title {
  font-size: 80px;
}

.pricing .card .card-body ul li {
  font-weight: bold;
}

.pricing .card .card-footer {
  background-color: transparent;
}

.pricing .card .card-footer .btn {
  font-size: 20px;
  padding: .8rem 1.5rem;
}

.pricing .card:hover {
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

/* Pricing Section css end */

/* Footer css start */

.footer {
  color: #fff;
  padding: 50px 60px;
}

.footer .footer-menu {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.footer .footer-menu .nav .nav-item {
  margin: 0 1rem;
}

.footer .nav .nav-item .nav-link {
  color: #fff;
  font-weight: bold;
  padding: .7rem 1rem;
}

.footer .nav .nav-item:hover .nav-link {
  background-color: #0D53BC;
  border-radius: 8px;
}

.footer .footer-menu .btn {
  margin-left: 1rem;
}

/* Footer css end */

/* Popup css start */

.modal {
  padding-right: 0 !important;
}

.modal .modal-content {
  border-radius: 12px;
  overflow: hidden;
  border: none;
  color: #222E3D;
}

.modal .modal-content .modal-header {
  padding: 1rem 1.5rem;
}

.modal .modal-content .modal-header .modal-title {
  color: #fff;
  display: flex;
  align-items: center;
}

.modal .modal-content .modal-header .close {
  font-size: 2.5rem;
  text-shadow: none;
  opacity: 1;
  font-weight: 400;
  color: #fff;
}

.modal .modal-content .modal-body {
  padding: 1.5rem;
}

.modal .modal-content .modal-footer {
  padding: 1rem 1.5rem;
  justify-content: space-between;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(12px - 1px);
  border-bottom-left-radius: calc(12px - 1px);
  background: #F7F7FA;
}

.modal .btn:hover {
  background-color: #fff;
  border-color: #E0E0E0;
  color: #155FCE;
}

.modal .btn-success:hover {
  background-color: rgba(81, 203, 133, .9);
  border-color: rgba(81, 203, 133, .9);
  color: #fff;
}

.modal .modal-content .modal-footer .btn-primary:hover svg {
  fill: #155FCE !important;
}

.modal .custom-file-input ~.custom-file-label span.after {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: inline-block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  background-color: transparent;
  border: none;
  color: #155FCE;
  font-weight: bold;
  cursor: pointer;
}

.modal .custom-file-input ~.custom-file-label::after {
  content: none;
}

.modal .custom-file {
  width: auto;
  height: calc(1.5em + 1.25rem + 2px);
  vertical-align: bottom;
}

.modal .custom-file .custom-file-input {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(1.5em + 1.25rem + 2px);
  cursor: pointer;
}

.modal .custom-file .custom-file-label {
  position: relative;
  display: flex;
  align-items: center;
  padding: .6rem 1rem;
  min-height: 46px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
}

.modal .custom-file-input:focus~.custom-file-label {
  border-color: #ced4da;
  box-shadow: none;
}

.modal .custom-file .custom-file-label svg {
  cursor: pointer;
}

.modal .phone-number {
  display: none;
}

.modal .btn.active {
  background-color: #F7F7FA;
}

.modal .btn.active svg {
  fill: #155FCE;
}

.modal .custom-file.active .custom-file-label {
  background-color: #F7F7FA;
}

.modal .custom-file.active .custom-file-label svg {
  fill: #155FCE;
}

.modal .call-info {
  font-size: 20px;
  font-family: 'Source Code Pro', monospace;
  padding: 1.5rem 2.5rem;
  border-radius: 16px;
  color: #828282;
}

.modal .call-info a, .modal .call-info span {
  font-weight: bold;
}

#reference-number .modal-body .btn {
  border: none;
  padding: .4rem;
}

#reference-number .modal-body .number {
  font-size: 20px;
  padding: .4rem;
  font-weight: bold;
  font-family: 'Source Code Pro', monospace;
  border-radius: 8px;
  margin-bottom: 1rem;
}

#reference-number .modal-footer {
  justify-content: flex-end;
}

/* Popup css end */
