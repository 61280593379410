@media (max-width: 1365.98px) {
  .footer {
    padding: 30px 30px;
  }
  .footer .footer-menu .nav .nav-item {
    margin: 0 0.5rem;
  }
}

@media (max-width: 1199.98px) {
  header {
    padding-top: 0rem;
  }
  .hero .hero-banner {
    padding: 10% 0 15% 0;
  }
  .hero .hero-banner h1 {
    font-size: 40px;
  }
  .hero .hero-banner h2 {
    font-size: 30px;
  }
  .section {
    padding: 40px 0;
  }
  .customer-container h2 {
    font-size: 2.25rem;
  }
  .customer-container {
    padding-top: 2rem;
    font-size: 18px;
  }
  .works-section .works-step {
    padding: 2rem 0;
  }
  .works-section .title {
    font-size: 3rem;
  }
  .works-section .step-title {
    font-size: 2.25rem;
  }
  .trial-section {
    padding: 100px 0 100px 0;
  }
  .trial-section h2 {
    font-size: 3rem;
  }
  .trial-section .call {
    margin: 0 0%;
  }
  .trial-section .call .call-info {
    font-size: 22px;
  }
  .dashboard-screen .description {
    padding-left: 15px;
  }
  .faqs {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .faqs .title {
    font-size: 50px;
  }
  .pricing {
    padding: 60px 0;
    font-size: 18px;
  }
  .pricing .title {
    font-size: 50px;
  }
  .pricing .card {
    padding: 20px;
  }
  .pricing .card .card-body .card-title {
    font-size: 70px;
  }
  .footer {
    padding: 30px 15px;
  }
  .footer .copyright {
    font-size: 14px;
  }
  .footer .footer-menu .nav .nav-item {
    margin: 0 0rem;
  }
  .footer .nav .nav-item .nav-link {
    padding: .5rem .8rem;
  }
}

@media (max-width: 991.98px) {
  .header-navbar {
    padding: 1.5rem 0 1rem 0;
  }
  .navbar-brand .logo {
    max-height: 65px;
  }
  header.sticky .navbar-brand .logo {
    max-height: 55px;
  }
  .header-navbar .navbar-collapse {
    position: absolute;
    top: 77px;
    right: 0;
    flex-direction: column !important;
    width: 290px;
    background-color: #155FCE;
    z-index: 2;
    margin: 0;
    padding: 1rem;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  }
  .header-navbar .navbar-nav {
    flex-direction: column !important;
    padding: 0;
  }
  .header-navbar .navbar-nav .nav-item {
    margin: 0 0rem;
  }
  header.sticky .header-navbar .navbar-collapse {
    top: 63px;
  }
  .hero .hero-banner {
    padding: 7% 0 15% 0;
  }
  .hero .hero-banner h1 {
    font-size: 36px;
  }
  .hero .hero-banner h2 {
    font-size: 24px;
    margin-bottom: 2rem;
  }
  .hero .hero-banner .hero-img {
    max-width: 90%;
  }
  .customer-container {
    text-align: center;
  }
  .customer-container h2 {
    padding-top: 1.5rem;
  }
  .works-step .step-description-left {
    padding-right: 0rem;
  }
  .works-step .step-description-right {
    padding-left: 0rem;
  }
  .trial-section {
    padding: 60px 0;
  }
  .trial-section h2 {
    padding-bottom: 1rem;
  }
  .dashboard-screen {
    padding: 50px 0 0 0;
  }
  .dashboard-screen .description {
    padding: 30px;
    max-width: 100%;
    float: none;
    text-align: center;
  }
  .dashboard-screen .dashboard-screen-img {
    width: calc(100% + 4%);
    margin-left: -4%;
    max-width: calc(100% + 4%);
  }
  .footer .copyright {
    font-size: 16px;
    text-align: center;
    padding-bottom: 1rem;
  }
  .footer .footer-menu {
    justify-content: center;
  }
  .faqs .faqs-questions {
    padding-top: 60px;
  }
  .modal .modal-body .button-group {
    text-align: center;
  }
  .modal .button-group .btn {
    width: 100%;
  }
  .modal .button-group .custom-file {
    width: 100%;
  }
  .modal .button-group .custom-file .custom-file-label {
    justify-content: center;
  }
  .modal .button-group span {
    margin: .5rem 0 !important;
    display: inline-block;
  }
  .modal .phone-number .d-flex {
    display: block !important;
  }
  .modal .phone-number .d-flex .btn {
    margin: .5rem 0 0 0 !important;
  }
  #start-free-trial .modal-footer {
    justify-content: center !important;
  }
  #start-free-trial .modal-footer .btn {
    width: 100%;
    margin: .25rem 0 !important;
  }
  .header-navbar .navbar-nav .btn {
    margin-left: 0rem;
    margin-top: .5rem;
  }
}

@media (max-width: 767.98px) {
  .navbar-brand .logo {
    max-height: 60px;
  }
  header.sticky .navbar-brand .logo {
    max-height: 50px;
  }
  .header-navbar .navbar-collapse {
    top: 74px;
  }
  header.sticky .header-navbar .navbar-collapse {
    top: 61px;
  }
  .section {
    padding: 30px 0;
  }
  .hero .hero-banner {
    padding: 7% 0 18% 0;
  }
  .hero .hero-banner h1 {
    font-size: 32px;
  }
  .customer-logos .slick-slide {
    min-height: 70px;
  }
  .hero .hero-banner h2 {
    font-size: 22px;
  }
  .customer-container h2 {
    font-size: 2rem;
  }
  .works-section .title {
    font-size: 2.5rem;
  }
  .works-section .step-title {
    font-size: 2rem;
  }
  .works-section {
    font-size: 18px;
    text-align: center;
  }
  .trial-section h2 {
    font-size: 2.5rem;
  }
  .trial-section .call {
    padding: 20px;
  }
  .trial-section .call .call-icon {
    width: 36px;
  }
  .trial-section .call .call-info {
    font-size: 20px;
    width: calc(100% - 56px);
    margin-left: 20px;
  }
  .dashboard-screen {
    padding: 30px 0 0 0;
    font-size: 18px;
  }
  .dashboard-screen h2 {
    font-size: 2rem;
  }
  .faqs .title {
    font-size: 40px;
  }
  .faqs {
    font-size: 18px;
  }
  .faqs .faqs-questions {
    padding-top: 40px;
  }
  .faqs .faqs-questions h3 {
    font-size: 1.5rem;
  }
  .pricing {
    padding: 30px 0;
  }
  .pricing .title {
    font-size: 40px;
  }
  .pricing .card {
    text-align: center;
  }
  .pricing .card .card-body .card-title {
    font-size: 60px;
  }
  .pricing .col-md-6 {
    margin-top: 1rem;
  }
  .pricing .col.text-center.pb-5 {
    padding-bottom: 0rem!important;
  }
  .footer .copyright {
    padding-bottom: 0.5rem;
  }
  .footer .footer-menu {
    display: block;
    text-align: center;
  }
  .footer .footer-menu .nav {
    display: inline-block;
    margin-bottom: .5rem;
  }
  .footer .footer-menu .nav .nav-item {
    display: inline-block;
  }
  .works-section {
    padding: 10px 0 40px 0;
  }
  .works-section .works-step {
    padding: 1rem 0;
  }
  .works-section .works-step:nth-child(2) .col-md-6:first-child {
    order: 2;
  }
  .works-section .step-title {
    margin-top: 2rem;
  }
  .works-section .works-step:nth-child(4) .col-md-6:first-child {
    order: 2;
  }
  .works-section .works-step:nth-child(4) .step-title {
    margin-top: 0rem;
  }
  .dashboard-screen .play-icon {
    max-width: 120px;
  }
  .pricing .card .card-footer .btn {
    font-size: 18px;
  }
  .footer .footer-menu .btn {
    margin-left: 0rem;
  }
  .modal .call-info {
    font-size: 24px;
  }
  .modal .modal-content .modal-header .modal-title {
    display: block;
  }
}

@media (max-width: 479.98px) {
  .navbar-brand .logo {
    max-height: 50px;
  }
  header.sticky .navbar-brand .logo {
    max-height: 44px;
  }
  header.sticky .header-navbar .navbar-collapse {
    top: 58px;
  }
  .header-navbar .navbar-collapse {
    top: 60px;
  }
  .header-navbar {
    padding: 1rem 0 1rem 0;
  }
  .hero .hero-banner {
    text-align: center;
  }
  .hero .hero-banner h2 {
    font-size: 20px;
  }
  .hero .hero-banner h2 br {
    display: none;
  }
  .hero .hero-banner .btn {
    font-size: 14px;
  }
  .hero .hero-banner .hero-img {
    max-width: 80%;
    right: -32%;
  }
  .customer-logos .slick-slide {
    min-height: 50px;
  }
  .customer-container {
    padding-top: 1rem;
  }
  .works-section .title {
    font-size: 2.25rem;
  }
  .faqs .title {
    font-size: 36px;
  }
  .pricing .title {
    font-size: 36px;
  }
  .pricing .card .card-body .card-title {
    font-size: 50px;
  }
  .footer {
    padding: 30px 0px;
  }
  .footer .copyright {
    font-size: 14px;
  }
  .footer .nav .nav-item .nav-link {
    padding: .5rem .5rem;
    font-size: 14px;
  }
  .footer .footer-menu .btn {
    font-size: 14px;
  }
  .trial-section .call {
    display: block;
    text-align: center;
  }
  .trial-section .call .call-icon {
    display: inline-block;
    margin-bottom: .5rem;
  }
  .trial-section .call .call-info {
    width: 100%;
    margin-left: 0px;
  }
  .trial-section {
    padding: 40px 0 50px 0;
  }
  .trial-section h2 {
    font-size: 2.25rem;
  }
  .faqs {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pricing .card {
    padding: 15px;
  }
  .modal .call-info {
    font-size: 20px;
    padding: 1.5rem 1.5rem;
  }
  .modal .modal-content .modal-header {
    padding: 1rem 1rem;
  }
  .modal .modal-content .modal-body {
    padding: 1rem;
  }
  .modal .modal-content .modal-footer {
    padding: 1rem;
  }
  .modal .modal-content .modal-header .modal-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 359.98px) {
  .hero .hero-banner .hero-img {
    right: -35%;
  }
  .customer-container h2 {
    font-size: 1.75rem;
  }
  .customer-container {
    font-size: 16px;
  }
  .works-section .title {
    font-size: 2rem;
  }
  .footer .nav .nav-item .nav-link {
    padding: .5rem 0.3rem;
  }
  .back-to-top {
    bottom: 25px;
  }
}
